// Admin Panel settings
$.fn.AdminSettings = function (settings) {
    var myid = this.attr("id");
    // General option for vertical header 
    var defaults = {
        Theme: true, // this can be true or false ( true means dark and false means light ),
        Layout: 'vertical', // 
        LogoBg: 'skin1', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6 
        NavbarBg: 'skin6', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6 
        SidebarType: 'full', // You can change it full / mini-sidebar
        SidebarColor: 'skin1', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
        SidebarPosition: false, // it can be true / false
        HeaderPosition: false, // it can be true / false
        BoxedLayout: false, // it can be true / false 
    };
    var sidebarPs;
    var settings = $.extend({}, defaults, settings);
    if(localStorage.getItem("infina_sidebar_type") !== null) {
        settings.SidebarTypeActual = localStorage.getItem("infina_sidebar_type");
    }else{
        settings.SidebarTypeActual = "full";
    }
    // Attribute functions 
    var AdminSettings = {
        // Settings INIT
        AdminSettingsInit: function () {
            AdminSettings.ManageThemeLayout();
            AdminSettings.ManageSidebarType();
            AdminSettings.ManageSidebarPosition();
        }
        , //****************************
        // ManageThemeLayout functions
        //****************************
        ManageThemeLayout: function () {
            switch (settings.Layout) {
            case 'horizontal':
                $('#' + myid).attr("data-layout", "horizontal");
                var setperfectscrollhorizontal = function () {
                    var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
                    if (width < 768) {
                        sidebarPs = new PerfectScrollbar($('.scroll-sidebar')[0], {
                            useBothWheelAxes: false,
                            suppressScrollX: true
                        });
                    }
                    else {
                        sidebarPs.destroy();
                        sidebarPs = null;
                    }
                };
                $(window).ready(setperfectscrollhorizontal);
                $(window).on("resize", setperfectscrollhorizontal);
                break;
            case 'vertical':
                $('#' + myid).attr("data-layout", "vertical");
                sidebarPs = new PerfectScrollbar($('.scroll-sidebar')[0], {
                    wheelPropagation: false,
                    useBothWheelAxes: false,
                    suppressScrollX: true
                  });
                break;
            default:
            }
        }
        , //****************************
        // ManageThemeLayout functions
        //****************************
        ManageSidebarType: function () {
            switch (settings.SidebarType) {
                //****************************
                // If the sidebar type has full
                //****************************     
            case 'full':
                $('#' + myid).attr("data-sidebartype", "full");
                $(".sidebartoggler i").removeClass("fa-arrow-alt-circle-right");
                $(".sidebartoggler i").addClass("fa-arrow-alt-circle-left");
                //****************************
                /* This is for the mini-sidebar if width is less then 1170*/
                //**************************** 
                var setsidebartype = function () {
                    var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
                    if (width < 1170) {
                        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
                        $(".sidebartoggler i").removeClass("fa-arrow-alt-circle-left");
                        $(".sidebartoggler i").addClass("fa-arrow-alt-circle-right");
                        $("#main-wrapper").addClass("mini-sidebar");
                    }
                    else {
                        if (settings.SidebarTypeActual === "full") {
                            $("#main-wrapper").removeClass("mini-sidebar");
                            $(".sidebartoggler").prop("checked", !1);
                            $(".sidebartoggler i").removeClass("fa-arrow-alt-circle-right");
                            $(".sidebartoggler i").addClass("fa-arrow-alt-circle-left");
                            $("#main-wrapper").attr("data-sidebartype", "full");
                        }
                        else {
                            $("#main-wrapper").addClass("mini-sidebar");
                            $(".sidebartoggler").prop("checked", !0);
                            $(".sidebartoggler i").removeClass("fa-arrow-alt-circle-left");
                            $(".sidebartoggler i").addClass("fa-arrow-alt-circle-right");
                            $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
                        }
                    }
                };
                $(window).ready(setsidebartype);
                $(window).on("resize", setsidebartype);
                //****************************
                /* This is for sidebartoggler*/
                //****************************
                $('.sidebartoggler').on("click", function () {
                    $("#main-wrapper").toggleClass("mini-sidebar");
                    if ($("#main-wrapper").hasClass("mini-sidebar")) {
                        $(".sidebartoggler").prop("checked", !0);
                        $(".sidebartoggler i").removeClass("fa-arrow-alt-circle-left");
                        $(".sidebartoggler i").addClass("fa-arrow-alt-circle-right");
                        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
                        localStorage.setItem("infina_sidebar_type", "mini-sidebar");
                    }
                    else {
                        $(".sidebartoggler").prop("checked", !1);
                        $(".sidebartoggler i").removeClass("fa-arrow-alt-circle-right");
                        $(".sidebartoggler i").addClass("fa-arrow-alt-circle-left");
                        $("#main-wrapper").attr("data-sidebartype", "full");
                        localStorage.setItem("infina_sidebar_type", "full");
                    }
                });
                break;
                //****************************
                // If the sidebar type has mini-sidebar
                //****************************       
            case 'mini-sidebar':
                $('#' + myid).attr("data-sidebartype", "mini-sidebar");
                //****************************
                /* This is for sidebartoggler*/
                //****************************
                $('.sidebartoggler').on("click", function () {
                    $("#main-wrapper").toggleClass("mini-sidebar");
                    if ($("#main-wrapper").hasClass("mini-sidebar")) {
                        $(".sidebartoggler").prop("checked", !0);
                        $("#main-wrapper").attr("data-sidebartype", "full");
                        localStorage.setItem("infina_sidebar_type", "full");
                    }
                    else {
                        $(".sidebartoggler").prop("checked", !1);
                        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
                        localStorage.setItem("infina_sidebar_type", "mini-sidebar");
                    }
                });
                break;
            default:
            }
        }
        , //****************************
        // ManageSidebarPosition functions
        //****************************
        ManageSidebarPosition: function () {
            var sidebarposition = settings.SidebarPosition;
            var headerposition = settings.HeaderPosition;
            switch (settings.Layout) {
            case 'vertical':
                if (sidebarposition == true) {
                    $('#' + myid).attr("data-sidebar-position", 'fixed');
                    $("#sidebar-position").prop("checked", !0);
                }
                else {
                    $('#' + myid).attr("data-sidebar-position", 'absolute');
                    $("#sidebar-position").prop("checked", !1);
                }
                if (headerposition == true) {
                    $('#' + myid).attr("data-header-position", 'fixed');
                    $("#header-position").prop("checked", !0);
                }
                else {
                    $('#' + myid).attr("data-header-position", 'relative');
                    $("#header-position").prop("checked", !1);
                }
                break;
            case 'horizontal':
                if (sidebarposition == true) {
                    $('#' + myid).attr("data-sidebar-position", 'fixed');
                    $("#sidebar-position").prop("checked", !0);
                }
                else {
                    $('#' + myid).attr("data-sidebar-position", 'absolute');
                    $("#sidebar-position").prop("checked", !1);
                }
                if (headerposition == true) {
                    $('#' + myid).attr("data-header-position", 'fixed');
                    $("#header-position").prop("checked", !0);
                }
                else {
                    $('#' + myid).attr("data-header-position", 'relative');
                    $("#header-position").prop("checked", !1);
                }
                break;
            default:
            }
        }
    , };
    AdminSettings.AdminSettingsInit();
};